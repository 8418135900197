import Vue from 'vue';
import App from './App.vue';
import TopMenu from '@/views/TopMenu.vue';
import VueAnalytics from 'vue-analytics';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(Vuetify);
Vue.use(VueRouter);
const vuetify = new Vuetify({
    theme: {
        themes: {
            light: {
                primary: colors.deepPurple,
                secondary: colors.grey,
                accent: colors.shades.black,
                error: colors.red.accent3,
            },
            dark: {
                primary: colors.blue.lighten3,
            },
        },
    },
});

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'TopMenu',
        component: TopMenu
    },
];
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

Vue.use(VueAnalytics, {
    id: '279305210',
    router,
    autoTracking: {
        screenview: true
    }
});
Vue.config.productionTip = false;
Vue.prototype.$static = true;

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app');
